jQuery('html').on('click', function() {
    jQuery('#main-nav [data-toggle]').each(function(k, v) {
        jQuery('#'+jQuery(v).data("toggle")).addClass('hidden').removeClass('animate-slideDown');
        jQuery(v).removeClass('bg-bg').removeClass('!text-secondary').removeClass('before:!text-secondary');
    });
});

jQuery('.submenu').on('click', function(e) {
    if(!(e.target.nodeName == "A" || e.target.nodeName == "BUTTON")) {
        e.preventDefault();
        e.stopPropagation();
    }
});

jQuery('#main-nav [data-toggle]').click(function(e) {
    e.preventDefault();
    e.stopPropagation();
    var active = jQuery(this).data("toggle");
    jQuery('#main-nav [data-toggle]').each(function(k, v) {
        jQuery(v).removeClass('bg-bg').removeClass('!text-secondary').removeClass('before:!text-secondary');
        if(active != jQuery(v).data("toggle")) {
            jQuery('#'+jQuery(v).data("toggle")).addClass('hidden').removeClass('animate-slideDown');
        }
    });
    jQuery('#'+active).toggleClass('hidden').toggleClass('animate-slideDown');
    if(jQuery('#'+active).is(':visible')) {
        jQuery(this).addClass('bg-bg').addClass('!text-secondary').addClass('before:!text-secondary');
    }
});


function mobilemenu(forceOpen) {
    if(forceOpen === undefined) forceOpen = false;

    if(jQuery('input#mobile-menu').is(":checked") && !forceOpen) {
        jQuery('.menu-toggle')
        .addClass("collapsed")
        .addClass('before:icon-menu')
        .removeClass('before:icon-close');
        jQuery('#menu-wrapper').addClass("opacity-0");
        window.setTimeout(function() {
            jQuery('#menu-wrapper').removeClass("block").addClass("opacity-0").addClass("hidden");
            jQuery('body').removeClass("h-full overflow-hidden lg:overflow-auto");
        }, 250);
    } else {
        jQuery('.menu-toggle')
        .removeClass("collapsed")
        .addClass('before:icon-close')
        .removeClass('before:icon-menu');
        jQuery('#menu-wrapper').removeClass("hidden").removeClass("opacity-0").addClass("block");
        jQuery('body').addClass("h-full overflow-hidden lg:overflow-auto");
        if(forceOpen) {
            jQuery('#menu-wrapper').find('input#mobile-menu').prop("checked", true);
        }
    }
}
var mobilemenuLoaded = false;
jQuery('.menu-toggle').on('click', function(event) {
    jQuery('#menu-wrapper > div input[type="checkbox"]').prop("checked", false);

    if(!mobilemenuLoaded) {
        event.stopPropagation();

        jQuery.ajax('/mobilemenu', {
            success:function(resp) {
                jQuery('#menu-wrapper > div').html('');
                jQuery('#menu-wrapper > div').append(resp);
                mobilemenuLoaded = true;
            }
        });
    }
    mobilemenu();
    
});