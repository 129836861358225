function iFrameResize(iframe) {
    iframe.style.height = (iframe.contentWindow.document.body.scrollHeight + 20) + 'px';
}
jQuery('.iframeResize').each(function (k, v) {
    jQuery(v).on("load", function () {
        iFrameResize(v);
    });
});
jQuery(window).on("resize", function () {
    jQuery('.iframeResize').each(function (k, v) {
        iFrameResize(v);
    });
});
if (jQuery('.iframeResize').length > 0) {
    setInterval(function () {
        jQuery('.iframeResize').each(function (k, v) {
            iFrameResize(v);
        });
    }, 500);
}
