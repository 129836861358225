jQuery(".ok-btn").on('click', function() {
    jQuery(this).parents('.modal-overlay').fadeOut(150);
    jQuery("body").removeClass('modal-open');
});
window.onclick = function(event) {
    if (jQuery(event.target).hasClass('modal-overlay') && !jQuery(event.target).hasClass('modal')) {
        jQuery('.modal-overlay').fadeOut(150);
        jQuery("body").removeClass('modal-open');
    }
}
window.openModal = function(elem) {
    jQuery(elem).fadeIn(200);
    jQuery("body").addClass('modal-open');
}